@import url(https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kalam&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap);



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@media (max-width: 500px) {
  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    position: fixed;
  height: 100%;
  overflow: hidden;
}
}

:root {
  --mainFont: 'Patrick Hand SC', cursive;
  --secondFont: 'Roboto', sans-serif;
  --Kalam: 'Kalam', cursive;
  --Elite: 'Special Elite', cursive;
}
.typistDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width:100vw;
  position:relative;
}

::-webkit-scrollbar {
  display: none;
}

.facebookLogin {
  margin-top: 25px;
  width: 100%;
  height: 60px;
  position: relative;
  color: white;
  font-size:16px;
  font-weight: bold;
  border-radius: 5px;
  background: #3A5A97;
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  cursor: pointer;
}

.facebookLogin > i {
  position: absolute;
  left: 13px;
  color: white;
  height: 30px;
  width:30px;
  font-size: 32px;
  align-self: center;
  top: 14px;
}

.fade-in-section {
  opacity: 0;
  /* transform: translateY(40vh); */
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section.fade-away {
  /* transform: translateX(-40vw); */
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
  opacity: 0;
}


.sectionWobble {
  width:100%;
  transition:0.4s;
  will-change: transform;
}

.homeBackgroundImage {
  display: flex;
  flex-direction: column;
  min-width: 105vw;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  background-size: cover;
  /* position: relative; */
  align-self: center;
  justify-content: space-around;
  margin-top:100px;
  position: absolute;
}

.howItWorks {
  width: 105vw;
  /* background-color: #fecee9; */
  background: radial-gradient(#fecee9, transparent);
  height: calc(1vh * 100 - 100px);
  height: calc(var(--vh, 1vh) * 100 - 100px);
  will-change: transform;
  transition: 0.4s;
  position: relative;
  /* margin-top:100px; */
  padding-top:30px;
}
.howItWorksIpad {
  width: 105vw;
  background: radial-gradient(#fecee9, transparent);
  height: 120vh;
  height: calc(1vh * 120);
  height: calc(var(--vh, 1vh) * 120);
  will-change: transform;
  transition: 0.4s;
  position: relative;
  background-color: white;
  padding-top: 60px
}

.howItWorksPhone {
  width: 100vw;
  background: radial-gradient(#fecee9, transparent);
  height: 1550px;
  will-change: transform;
  transition: 0.4s;
  position: relative;
  padding-top: 10px;
  padding-bottom:200px;
}

.blueBottomContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 100px);
  /* height: calc(var(--vh, 1vh) * 100 - 100px); */
  position: relative;
  margin-top:100px;
  background: radial-gradient(#24b9ed, transparent);
}

.blueBottomContainerPhone {
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex-grow: 1;
  height: auto;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  /* background-color: #24b9ed; */
  background: radial-gradient(#24b9ed, transparent);

  /* height: calc(100vh - 70px); */
  /* height: calc(var(--vh, 1vh) * 100 - 70px); */
  position: relative;
}

.transgroup {
  position: relative;
  /* display: flex; */
}

.route-section {
  position: absolute;
        width: 100%;
        top: 0;
        left: 0;
}
.App {
  width: 100%;
  /* height:100vh; */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  margin:0;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  -webkit-animation: fadein 2s linear;
          animation: fadein 2s linear;
}
.app {
  width: 100%;
  /* height: 100vh; */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  position: relative;
  display:flex;
  flex-direction: column;
  -webkit-animation: fadein 2s linear;
          animation: fadein 2s linear;
  justify-content: flex-start;
}

.panel-wrapper-first {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
}
.panel-wrapper-second {
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
  margin-top:calc(1vh * 100);
  margin-top:calc(var(--vh, 1vh) * 100);
}

.panel-wrapper-secondPhone {
  width: 100%;
  height: 1550px;
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
  margin-top:calc(1vh * 100);
  margin-top:calc(var(--vh, 1vh) * 100);
}

.two {
height: 110%;
background:#FECEE9;
}

.panel-wrapper-third {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
  margin-top:calc(1vh * 200 - 100px);
  margin-top:calc(var(--vh, 1vh) * 200 - 100px);
  
}
.third {
  margin-top: calc(1vh * 210);
  margin-top: calc(var(--vh, 1vh) * 210);
}

.panel-wrapper-thirdPhone {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
  margin-top:calc(1vh * 100 + 1550px);
  margin-top:calc(var(--vh, 1vh) * 100 + 1550px);
  
}

.panelfirst {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
}

.panelsecond {
  width: 100%;
  height: 100%;
  top: 0;
  position: relative;
}
.two .panelsecond {
  position:relative;
}

.panelsecondPhone {
  width: 100%;
  height: 1550px;
  top: 0;
  position: relative;
}

.panelthird {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
}

.panelfirst span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%,0);
}

.panelsecond span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%,0);
}

.panelthird span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%,0);
}

.logoText {
  font-family: 'Kalam', cursive;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2px;
}

.tempdiv {
  
   /* flex: 1; */
   width: 50%;
   justify-content: space-around;
   align-items: center;
   display: flex; flex-direction: column; 
   border-radius: 20; flex-direction: row; 
   flex-wrap: wrap;
   background-color: #ffffff;
/* background-image: url("data:image/svg+xml;%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235625a3' fill-opacity='0.18'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
}

.downloadn {
  font-size: 12;
  font-family: Futura;
  -webkit-animation: downloadtext 5s;
          animation: downloadtext 5s;
  transition: 1s;
}

@-webkit-keyframes downloadtext {
  0% { color : black; transform: translateX(0%); transition: 1s}
  33% {color: red; transform: translateX(5%)}
  66% { color: green; transform: translateX(10%)}
  100% { color: pink; transform: translateX(5%)}
}

@keyframes downloadtext {
  0% { color : black; transform: translateX(0%); transition: 1s}
  33% {color: red; transform: translateX(5%)}
  66% { color: green; transform: translateX(10%)}
  100% { color: pink; transform: translateX(5%)}
}

@-webkit-keyframes initial{
  from {
    display: none; opacity: 0;
  }
  to {
    display: block; opacity: 1;
  }
}

@keyframes initial{
  from {
    display: none; opacity: 0;
  }
  to {
    display: block; opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0
  } to { opacity : 1}
}

@keyframes fadein {
  from {
    opacity: 0
  } to { opacity : 1}
}

.div2 {
  /* flex: 0.5; */
  /* align-self: flex-start; */
  /* margin-left: 5%; */
  justify-content: space-between;
  flex-direction: row;
  display:flex;
  flex:1 1;
 box-shadow: 10px 9px 10px black;
  /* margin-right: 20px; */
  border-bottom-right-radius: 5px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 15px;
  background: linear-gradient(90deg, white,white 47%, #24b9ed)
  /* background-color: #bd68b8; */
}

.Bottom {
  background-color: #24b9ed;
}


.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 10vh;
  pointer-events: none;
  margin-left: 2vh;
}
.App-text {
  max-height: 100vh;
  height: 10vh;
  -webkit-animation: none 0s ease 0s 1 normal none running infinite 2s linear;
          animation: none 0s ease 0s 1 normal none running infinite 2s linear;
  -webkit-animation: initial infinite 2s linear;
          animation: initial infinite 2s linear;
}


.App-link {
  color: #61dafb;
}

.screenshot1 {
  display: flex; 
  /* margin-right: 20px;
  margin-left: 20px; */
  flex:1 1;
  object-fit: contain;
  width: 100%;
  height: auto;

}

.screenshot2{
  display: flex; 
  /* margin-right: 20px;
  margin-left: 20px; */
  flex:1 1;
  justify-content:center;
  width: 100%;
  height: auto;
 
  object-fit: contain;
}

.screenshotx{
  display: flex; 
  justify-content:center;
  /* cursor:pointer; */
  align-items: center;
  object-fit: contain;
  width: 150px;
  height: 200px;
}
.screenshotx:hover{
  opacity: 0.5;
}
.downloadios {
  height: 7vh;
  align-self: flex-end;
  display: flex;
  transition: 1s;
  /* box-shadow: 5px 5px 5px black; */
}

.downloadios:hover {
  height: 10vh;
  transition: 1s
}

.downloadgoogle:hover {
  height: 10vh;
  transition: 1s
}

.downloadgoogle {
  height: 7vh;
  align-self: flex-end;
  display: flex;
  margin-left: 5%;
  transition: 1s
}


/* Nav Bar from this point downwards */

.is-active {
  -webkit-text-decoration: underline dotted red;
          text-decoration: underline dotted red; 
  color: #fecee9;
  font-weight: 900;
}

.Home {
  font-size:30px;
  margin-right:0px;
  color: black;
  text-decoration: none;
  /* background: linear-gradient(0deg, black white); */
  padding: 10px;
  border-top-right-radius: 00px;
  border-top-left-radius: 10px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  /* text-shadow: grey 2px 2px 2px ; */
  /* border: 2px solid black; */
} .Homemobile {
  font-size:35px;
  margin-right:10px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  color: #000000;
  margin-top:20px;
  margin-bottom: 0px;
  text-decoration: none;
 
}

.Home:hover {
  color: #fecee9;
  border-color: #24b9ed;

}


.Sign-up {
  font-size:30px;
  margin-right:0px;
  color: black;
  text-decoration: none;
  /* background: linear-gradient(0deg, black white); */
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 0px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  /* text-shadow: grey 2px 2px 2px ; */
  /* border: 2px solid black; */
} .Sign-upmobile {
  font-size:35px;
  margin-right:10px;
  color: #24b9ed;
  text-decoration: none;
 
}

.Sign-up:hover {
  color: #fecee9;
  border-color: #24b9ed;

}

.About-us:hover {
  color: #fecee9;
  border-color: #24b9ed;
}

.About-us{
  font-size: 30px;
  margin-right:20;
  color: black;
  text-decoration: none;
  padding: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  /* border: 2px solid black; */
  /* text-shadow: grey 2px 2px 2px ; */
  /* box-shadow: 0px 30px 0px #ffffff; */
  /* border: 5px solid #fecee9 */
} .About-usmobile{
  font-size: 35px;
  margin-right:10;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  color: black;
  margin-top: 20px;
  text-decoration: none;
}

.div-total {
  flex-direction: row;
  justify-content: center;
}

.div-links {
  flex: 0.5 1;
}

.div-links2 {
  margin-top: 3vh;
  flex: 0.5 1;
}



@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img3 {
  position: absolute;
  top:45px;
  height: 50px;
  width: undefined;
  align-self: center;
  display: 'flex';
  -webkit-animation: loadinglogo 0.5s linear;
          animation: loadinglogo 0.5s linear;
}

.imgPhone {
  position: absolute;
  top:25px;
  height: 40px;
  width: undefined;
  align-self: center;
  display: 'flex';
  -webkit-animation: loadinglogo 0.5s linear;
          animation: loadinglogo 0.5s linear;
}

@-webkit-keyframes loadinglogo {
  0% {
    transform: translateY(0%) rotate(0deg);
    display: none; opacity: 0; height: 400px; 
  }
  30% {
    transform: translateY(60%) rotate(90deg);
    display: block; opacity: 1; height: 500px;
  }
  100% {
    transform: translateY(30%) rotate(-180deg);
    display: none; opacity: 0; height: 50px;
  }
}

@keyframes loadinglogo {
  0% {
    transform: translateY(0%) rotate(0deg);
    display: none; opacity: 0; height: 400px; 
  }
  30% {
    transform: translateY(60%) rotate(90deg);
    display: block; opacity: 1; height: 500px;
  }
  100% {
    transform: translateY(30%) rotate(-180deg);
    display: none; opacity: 0; height: 50px;
  }
}


.g1 {
  height: auto;
   width: 100%;
   align-self: center;
   transition: 2s;
   object-fit: contain;
}

.g1:hover {
  transform: scale(1.25);
  transition: 2s;
  z-index: 100;
}

.g2 {
  height: auto;
   width: 100%;
   align-self: flex-start;
   transition: 2s;
   object-fit: contain;
}

.g2:hover {
  transform: scale(1.25);
  transition: 2s;
  z-index: 100;
}

.g3 {
  height: auto;
   width: 100%;
   align-self: flex-start;
   transition: 2s;
   object-fit: contain;
}

.g3:hover {
  transform: scale(1.25);
  transition: 2s;
  z-index: 100;
}

.g4 {
  height: auto;
   width: 100%;
   align-self: flex-start;
   transition: 2s;
   object-fit: contain;
}

.g4:hover {
  transform: scale(1.25);
  transition: 2s;
}


/* About us page */

.aboutx:hover {
  transform:  scale(1.1) translateX(5%);
        transition: ease-out 0.2s;
        border-radius: 3px;
        align-self: center
      /* background-color: #b1ddf0; */
      /* border-width: 10px; */
} .abouty:hover {
  transform:  scale(1.1) translateX(-5%);
        transition: ease-out 0.3s;
        border-radius: 3px;
        align-self: center
      /* background-color: #b1ddf0; */
      /* border-width: 10px; */
} .aboutz:hover {
  transform:  scale(1.1) translateX(5%);
        transition: ease-out 0.3s;
        border-radius: 3px;
        align-self: center
      /* background-color: #b1ddf0; */
      /* border-width: 10px; */
}


.aboutx {
  border: 5px solid #24b9ed;
   display:flex;
   border-left-color:#24b9ed;
   border-radius:20px;
   border-left-width: 40px;
   flex-direction:column;
   padding: 10px;
   flex-shrink: 1;
   border-left-style:solid;
   background-color:#e4f7eb;
   margin-right: 22%;
   margin-bottom:75px;
   margin-left:5%;
   background-color: white;
   transition: 0.3s ease-out;
   margin-top: 20px;

}

.abouty {
  border: 5px solid #7032ed;
   display:flex;
   border-right-color:#7032ed;
   border-radius:20px;
   border-right-width: 40px;
   flex-direction:column;
   padding: 10px;
   flex-shrink: 1;
   border-right-style:solid;
   background-color:#e4f7eb;
   margin-left: 22%;
   margin-bottom:75px;
   margin-right:5%;
   background-color: white;
   transition: 0.3s ease-out;
   margin-top: 20px;
}

.container {
  display:flex;
   background-color:#24b9ed;
   border-radius: 20px;
   padding-left: 10px;
   flex-direction: row;
   /* flex-shrink:1; */
   margin: 0;
   /* float: left */
   align-items:center;
}

.aboutz {
  border: 5px solid #287544  ;
   display:flex;
   border-left-color:#287544   ;
   border-radius:20px;
   border-left-width: 40px;
   flex-direction:column;
   padding: 10px;
   flex-shrink: 1;
   border-left-style:solid;
   background-color:#e4f7eb;
   margin-right: 22%;
   margin-bottom:75px;
   margin-left:5%;
   background-color: white;
   transition: 0.3s ease-out;
   margin-top: 20px;
}

.aboutmobile {
  border: 5px solid #287544  ;
   display:flex;
   border-radius:20px;
   flex-direction:column;
   padding: 10px;
   flex-shrink: 1;
   background-color:#e4f7eb;
   margin-right: 4%;
   margin-bottom:75px;
   margin-left:4%;
   background-color: white;
   transition: 0.3s ease-out;
   margin-top: 20px;
}

.containermobile {
  display:flex;
   background-color:#287544;
   border-radius: 20px;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   /* padding-left: 10px; */
   /* flex-shrink:1; */
   margin: 0;
   /* float: left */
}



.see-books {
  flex: 1 1 auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 3px 4px 2px grey;
  border-radius: 20px;
  background: linear-gradient(45deg,   #cc82e7,#fecee9   );
  background-size: 400% 400%;
  -webkit-animation: AnimationName 4s linear infinite;
          animation: AnimationName 4s linear infinite;
  /* transition: 4s; */
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  font-size: 20px;
  

}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 0%; }
 50% {background-position: 200% 200% } 
  /* 75%{background-position:100% 200%; } */ 
  100%{background-position: 0% 0%}
  /* 100%{background-position:0% 50%} */
}

@keyframes AnimationName {
  0%{background-position:0% 0%; }
 50% {background-position: 200% 200% } 
  /* 75%{background-position:100% 200%; } */ 
  100%{background-position: 0% 0%}
  /* 100%{background-position:0% 50%} */
}



.sticky{
  overflow: hidden;
  background-color: black;
  position: absolute; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  justify-content:space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
  z-index: 102;
  height: 60px;
  margin-bottom: 20px;
  box-shadow: 0px 9px 5px black;
}

.color {
  color: #433c4d
}


.see-booksdesktop {
  /* flex: 1 1 auto; */
  flex-shrink: 1;
  display: inline-block;
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  padding: 0px;
  height: 50px;
  width: 30%;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0px 0px 5px 3px black ;
  border-radius: 20px;
  border: 0px solid white;
  background: linear-gradient(90deg,   rgb(243, 100, 100),rgb(40, 37, 206), green);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 20s linear infinite;
          animation: AnimationName 20s linear infinite;
  /* transition: 4s; */
  text-decoration: none;

} .see-booksdesktop:hover { cursor: pointer; box-shadow: 0px 0px 7px 10px grey ; }

.see-booksdesktop-inner {
  /* flex: 1 1 auto; */
  flex-shrink: 1;

  align-self: center;
  text-align: center;
  text-transform: uppercase;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  font-size: 20px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);

}

.jerky {
display: flex;
overflow: hidden;
margin-left: 20px;
margin-bottom: 20px;
height: 70%;
justify-content:flex-start;
align-items:flex-start
/* background: linear-gradient(135deg;
 white, #fecee9) */
/* box-shadow: 2px 5px 0px 4px grey ; */
}

@-webkit-keyframes newanimate {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translate(200%, 0%);
  }
  50% {
    transform: translate(200%, 200%)
  }
  75% {
    transform: translate(0%,200%)
  }
  100% {
    transform: Translate(0%,0%)
}
}

@keyframes newanimate {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translate(200%, 0%);
  }
  50% {
    transform: translate(200%, 200%)
  }
  75% {
    transform: translate(0%,200%)
  }
  100% {
    transform: Translate(0%,0%)
}
}

@-webkit-keyframes newanimateY {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translate(-200%, -0%);
  }
  50% {
    transform: translate(-200%, -200%)
  }
  75% {
    transform: translate(0%,-200%)
  }
  100% {
    transform: Translate(0%,0%)
}
}

@keyframes newanimateY {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translate(-200%, -0%);
  }
  50% {
    transform: translate(-200%, -200%)
  }
  75% {
    transform: translate(0%,-200%)
  }
  100% {
    transform: Translate(0%,0%)
}
}

@-webkit-keyframes lolz {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 400% 400%; z-index: 101;;
  }
}

@keyframes lolz {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 400% 400%; z-index: 101;;
  }
}

.sally {
  height: 100%;
  width: 1%;
  display: flex;
  z-index: 102;
  background-color: #24b9ed;
  -webkit-animation: sallyz 5s infinite linear;
          animation: sallyz 5s infinite linear;
  align-self: flex-start;
  position: relative;
  /* background:linear-gradient(90deg, white 30%, black);
  background-size: 200% 200%;
  background-repeat: no-repeat; */
  top: 0;
  left: 0
}

@-webkit-keyframes sallyz {
  0% {
    top: 0%;
    left: 0%
  }
  50% {
    transform:  scaleX(300); 
    
  }

  100% {

  }
}

@keyframes sallyz {
  0% {
    top: 0%;
    left: 0%
  }
  50% {
    transform:  scaleX(300); 
    
  }

  100% {

  }
}

.whyusebookey {
  height: 100%;
  width: 100%;
  display: inline-block;
  z-index: 102;
  background: linear-gradient(90deg, #24b9ed, #24b9ed 33%, #fecee9 33%, #fecee9 66%, #b2e28a 66%, #b2e28a 100%);
  align-self: stretch;
  position: relative;
  flex: 1 1;
  transition: 2s;
}


.FUCKOFF {
  opacity: 1;
}

.FUCKOFFx{
  opacity: 0;
  transition: 1s;
  display: none;
  transform: translateY(100%);
}

.bar1{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  top: 0;
  left: 0;
}

.bar1x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 500ms;
  width: 30%;
  justify-content: center;
  height: 30%;
  display: flex;
  /* box-shadow: 0px 0px 5px 5px black inset; */

  /* border: 10px solid black; */
  /* border-radius: 20px; */
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  /* animation: newanimate 4s infinite linear; */

}

.text1 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text1x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  color: white;
  align-self: center;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  padding: 5px;
  transition-delay: 1000ms;

}

.bar2{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  bottom: 100%;
  align-self: center;
  left: 33%;
}

.bar2x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 1100ms;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  /* border: 10px solid black; */
  align-self: center;
  border-radius: 0px;
  /* box-shadow: 0px 0px 5px 5px black inset; */
  padding: 10px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

}

.text2 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text2x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  color: white;
  align-self: center;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  padding: 5px;
  transition-delay: 1600ms;

}


.bar3{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  bottom: 100%;
  align-self: flex-end;
  left: 66%;
}

.bar3x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 1700ms;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  /* border: 10px solid black; */
  align-self: flex-end;
  /* box-shadow: 0px 0px 5px 5px black inset; */
  /* border-radius: 20px; */
  padding: 10px;
  /* animation: newanimateY 3s infinite linear; */
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}

.text3 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text3x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  color: white;
  align-self: center;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  padding: 5px;
  transition-delay: 2200ms;

}


.seebooksimg {
  height: 180px;
  width: auto;
  object-fit: contain;
  margin: 10px;
  margin-top:10px;
  max-width: 100%;
}

.amazonbutton {
  background: linear-gradient(90deg, #24b9ed, #fecee9);
  background-size: 200% 200%;
  background-repeat: no-repeat;
  -webkit-animation: amazonanimate 2s linear infinite;
          animation: amazonanimate 2s linear infinite;
  /* flex-shrink: 1; */
  padding: 10px;
  width:30%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  display: flex;
  /* width: 330px; height: 330px; */
  border-radius: 10px;
  color: black;
  font-size: 18px;
  text-align: center;
  border: 10px  solid #ffffff ;
  cursor: pointer;

}

@-webkit-keyframes amazonanimate {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes amazonanimate {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.mobileMatchesContainer {
  flex-direction: column;
   display:flex;
   box-sizing: border-box;
   width:100%;
   height:calc(100vh - 140px);
   overflow-y: scroll;
}

.Main {
  flex-direction: row;
   display:flex;
   justify-content:space-between;
   margin-top: 30px;
   flex-wrap: wrap;
   width:100vw;
   height:calc(100vh - 100px);
   /* animation: xax 3s ease-out infinite; */
   /* background-color: var(--zander); */
  /* transform:  rotate3d(1,-1,0.7, 60deg) ;
  transition: ease-out 0.5s;
  background-color: grey; */
}

@-webkit-keyframes xax {
  0% {
    /* transform: rotate3d(10,10,10, 0deg) */
  }

  100% { transform: rotate3d(0,0,var(--zander), 10deg); opacity: 0 }
}

@keyframes xax {
  0% {
    /* transform: rotate3d(10,10,10, 0deg) */
  }

  100% { transform: rotate3d(0,0,var(--zander), 10deg); opacity: 0 }
}


.sort-button:hover {
  cursor: pointer
}

.desktopviewbooksbackground {
  display:flex;
   z-index: 101;
   /* background-color:#24b9ed; */
   position:relative;
   overflow: scroll;
   /* top: 0px; */
   width: 100%;
   transition: 1s;
   border-width:10px;
    border-radius:10px;
    border-color:black;
}

.Pressx {
  /* background: linear-gradient(180deg, #24b9ed ,#0d607e); */
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;

}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.card {
  position: relative;
  z-index: 10000;
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
  border-radius: 10px;
  padding: 15px;
  z-index: 201;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

.modal {
  display: flex;
  z-index: 102;
  position: fixed;
  align-self:center;
  justify-content: center;
  align-items:center;
  width: 100%;
  height: 100%;
  opacity: 0;

}

.modalx {
  display: flex;
  z-index: 102;
  position: fixed;
  align-items:center;
  flex-shrink: 1;
  opacity: 1;
  transition: 1s;
  padding: 50px;
  background-color: white;
  left: 45%;
  top: 20%;
  -webkit-animation: modal-spring ease-out 1s;
          animation: modal-spring ease-out 1s

}

.invis {
  display: none
}

@-webkit-keyframes modal-spring {
  0% {
    top: 100%;
  }

  50% {
    top: 5%
  }
  85% {
    top: 25%
  }

  100% {
    top: 20%; 
  }
}

@keyframes modal-spring {
  0% {
    top: 100%;
  }

  50% {
    top: 5%
  }
  85% {
    top: 25%
  }

  100% {
    top: 20%; 
  }
}




.container-signup {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: #ccc7d4 2px 2px 5px;
  padding: 20px;
  width: 70%;
  margin: 30px;
  background-color: rgb(236, 226, 238);

}


.pass-match {
  color: #00bd7c;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-family: var(--secondFont);
}

.pass-fail {
  color: red;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-family: var(--secondFont);
}


.first-msg {
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-bottom: 5px
}

.first-msg-invis {
  display: none;
  flex-direction:row;
  justify-content:flex-start;
  margin-bottom: 5px
}

.second-msg {
  display: flex;
  flex-direction:row;
  justify-content:flex-start
}

.second-none {
  display: none;
  flex-direction:row;
  justify-content:flex-start
}

.second-vis {
color: red;
font-size: 12px;
font-family: 'Roboto', sans-serif;
font-family: var(--secondFont);
opacity: 1
}

.second-invis {
  color: #00bd7c;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-family: var(--secondFont);
  opacity: 1
  }
.condition-vis {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  align-self:flex-start;
  margin-bottom: 10px;
  opacity: 1
}

.condition-invis {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  align-self:flex-start;
  margin-bottom: 10px;
  opacity: 0
}

.password-text {
   font-size: 30px;
   font-weight: 400;
   color: white;
   font-family: 'Roboto', sans-serif;
   font-family: var(--secondFont);
   align-self:center;
   margin-top: 138px;
   position: absolute
}

.email-cond {
  display: flex
}

.email-invis {
  display: none
}

.signup-button {
  background-color: rgb(177, 125, 190);
  padding-left:20px ;
  font-size: 20px;
  color: white;
  width: 40%;
  border-width: 0px;
  padding-right:20px ;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}


.app-navbar {
  display: flex;
  top: 0;
  padding-left:40px;
  align-self: flex-start;
  flex-direction: row;
  width: 85%;
  justify-content: flex-start;
}

.Profile:hover {
  color: #fecee9;
  border-color: #24b9ed;
  cursor: pointer;
}

.Profile{
  font-size: 30px;
  color: black;
  text-decoration: none;
  background-color: white;
  padding: 10px;
  margin: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  border: 2px solid black;
  /* text-shadow: grey 2px 2px 2px ; */
  /* box-shadow: 0px 30px 0px #ffffff; */
  /* border: 5px solid #fecee9 */
} 

.Books:hover {
  color: #fecee9;
  border-color: #24b9ed;
  cursor: pointer;
}

.Books{
  font-size: 30px;
  color: black;
  text-decoration: none;
  background-color: white;
  padding: 10px;
  margin: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  font-family: 'Patrick Hand SC', cursive;
  font-family: var(--mainFont);
  border: 2px solid black;
  /* text-shadow: grey 2px 2px 2px ; */
  /* box-shadow: 0px 30px 0px #ffffff; */
  /* border: 5px solid #fecee9 */
} 




@media (max-width: 1199px) {
  .jerky {
    display: flex;
    overflow: hidden;
    margin-left: 20px;
    margin-bottom: 20px;
    height: 600px;
    display:flex; 
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction: row;

    /* background: linear-gradient(135deg, white, #fecee9) */
    /* box-shadow: 2px 5px 0px 4px grey ; */
    }

    .Main {
      flex-direction: column;
       display:flex;
       justify-content:space-between;
       margin-top: 30px;
       flex-wrap: wrap;
       flex: 1 1;
       overflow: hidden;
}

.whyusebookey {
  height: 100%;
  width: 100%;
  display: inline-block;
  z-index: 102;
  background: linear-gradient(90deg, #24b9ed, #24b9ed 33%, #fecee9 33%, #fecee9 66%, #b2e28a 66%, #b2e28a 100%);
  align-self: stretch;
  position: relative;
  flex: 1 1;
  transition: 2s;
}

.menu-invis {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 100;
  height:0px;
  width: 0px;
  display: none;
  background-color: #a7afd1;

}

.menu-vis {
  bottom: 0;
  left: 0;
  height: 0px;
  width: 0px;
  position: absolute;
  z-index: 100;
  background-color: #b5bddf;
  -webkit-animation: menubar 0.2s cubic-bezier(1, 0, 0, 1) forwards ;
          animation: menubar 0.2s cubic-bezier(1, 0, 0, 1) forwards ;
  display: fixed;
  flex-direction:column ;
  
}

@-webkit-keyframes menubar {
  0% {
    width: 0px;
    height: 0px;
    bottom: 0;
    opacity: 0;
    left:0
  }
  100% {
    width: 100%; height: 100%; position: fixed; opacity: 1;
    
  }
}

@keyframes menubar {
  0% {
    width: 0px;
    height: 0px;
    bottom: 0;
    opacity: 0;
    left:0
  }
  100% {
    width: 100%; height: 100%; position: fixed; opacity: 1;
    
  }
}


.FUCKOFF {
  opacity: 1;
}

.FUCKOFFx{
  opacity: 0;
  transition: 1s;
  display: none;
  transform: translateY(100%);
}

.bar1{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  opacity:0;
  top: 0;
  left: 0;
}

.bar1x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 500ms;
  width: 30%;
  height: 30%;
  justify-content: center;
  display: flex;
  background-color: #fecee9;
  background: linear-gradient(135deg, rgb(19, 114, 77), #24b9ed);
  /* border: 10px solid black; */
  box-shadow: 0px 0px 5px 5px black inset;
  /* border-radius: 20px; */
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  /* animation: newanimate 4s infinite linear; */

}

.text1 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text1x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  color: white;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  padding: 5px;
  transition-delay: 1000ms;

}

.bar2{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  bottom: 100%;
  opacity: 0;
  align-self: center;
  left: 33%;
}

.bar2x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 1100ms;
  width: 30%;
  height: 30%;
  justify-content: center;
  display: flex;
  background-color: #fecee9;
  /* border: 10px solid black; */
  align-self: center;
  border-radius: 0px;
  box-shadow: 0px 0px 5px 5px black inset;
  padding: 10px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(135deg, #24b9ed, #2647dd);
}

.text2 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text2x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  color: white;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  padding: 5px;
  transition-delay: 1600ms;

}


.bar3{
  width: 33%;
  height: 0%;
  background-color: black;
  display: flex;
  bottom: 100%;
  opacity: 0;
  align-self: flex-end;
  left: 66%;
}

.bar3x {
  transform: scaleY(200%);
  transition: 1s;
  transition-delay: 1700ms;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  background-color: #fecee9(47, 19, 51);
  /* border: 10px solid black; */
  align-self: flex-end;
  box-shadow: 0px 0px 5px 5px black inset;
  /* border-radius: 20px; */
  padding: 10px;
  /* animation: newanimateY 3s infinite linear; */
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  background: linear-gradient(135deg, #2647dd, #010e49)
}

.text3 {
  visibility: none;
  opacity: 0;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  padding: 5px;
  color: white;
}

.text3x {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  font-family: 'Kalam', cursive;
  font-family: var(--Kalam);
  color: white;
  align-self: center;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  padding: 5px;
  transition-delay: 2200ms;

}

.menu-burger {
  height: 22px;
  padding: 10px;
  top: 0;
  left: 0;
    width: 22px;
  /* color:black; */
  background-color: black;
  z-index: 100;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.menu-burger-shown {
  height: 22px;

  padding: 10px;
  top: 0;
  left: 0;
  position: fixed;
  width: 22px;
  /* color:black; */
  background-color: black;
  z-index: 100;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.menu-invis {
  bottom: 0;
  left: -100%;
  position: absolute;
  z-index: 100;
  height:100%;
  width: 100%;
  transition: 0.3s;
  display: flex;
  background-color: #bbc2e0;

}

.menu-vis {
  bottom: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  background-color: #bdc4e4;
  -webkit-animation: menubar 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards ;
          animation: menubar 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards ;
  display: fixed;
  flex-direction:column ;
  
}

@keyframes menubar {
  0% {
    width: 100%;
    height: 100%;
    bottom: 0;
    opacity: 0;
    left:-100%
  }
  100% {
    width: 100%; height: 100%; position: fixed;left:0px;
  }
}
}

.ellipse {
  fill: red;
  width:100px;
  height:100px;
  z-index: 10000;
}

.svg{
  display: flex;
  height: 100px;
  width: 100px;
}

@media (max-width: 500px) {
.app {
  width: 100%;
  /* height: 100vh; */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  position: relative;
  display:flex;
  flex-direction: column;
  -webkit-animation: fadein 2s linear;
          animation: fadein 2s linear;
  justify-content: flex-start;
}
.facebookLogin {
  margin-top: 25px;
  width: 100%;
  height: 60px;
  position: relative;
  color: white;
  font-size:14px;
  font-weight: bold;
  border-radius: 5px;
  background: #3A5A97;
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  cursor: pointer;
}

.facebookLogin > i {
  position: absolute;
  left: 6px;
  color: white;
  height: 30px;
  width:30px;
  font-size: 22px;
  align-self: center;
  top: 19px;
}
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

@media (min-width: 1400px) and (min-height: 1200px) {
  .panel-wrapper-second {
    width: 100%;
    height: calc(100% - 100px - 20vh);
    position: absolute;
    top: 0;
    overflow: hidden;
    clip: rect(0, auto, auto, 0);
    -webkit-clip-path: border-box;
    margin-top:calc(1vh * 100);
    margin-top:calc(var(--vh, 1vh) * 100);
  }

  .panel-wrapper-third {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  -webkit-clip-path: border-box;
  margin-top:calc(1vh * 200 - 100px - 20vh);
  margin-top:calc(var(--vh, 1vh) * 200 - 100px - 20vh);
  
}
}
